import classNames from "classnames";
import type { CSSProperties, MouseEvent } from "react";
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

interface ModalProps {
  afterClose?: () => void;
  bodyStyle?: React.CSSProperties;
  bodyClassName?: string;
  cancelButtonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  cancelText?: React.ReactNode;
  centered?: boolean;
  closable?: boolean;
  closeIcon?: React.ReactNode;
  confirmLoading?: boolean;
  destroyOnClose?: boolean;
  hasHeader?: boolean;
  footer?: React.ReactNode | null;
  forceRender?: boolean;
  keyboard?: boolean;
  mask?: boolean;
  maskClosable?: boolean;
  maskStyle?: React.CSSProperties;
  modalRender?: (node: React.ReactNode) => React.ReactNode;
  okButtonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  okText?: React.ReactNode;
  okType?: string;
  style?: React.CSSProperties;
  title?: React.ReactNode;
  open: boolean;
  width?: string | number;
  wrapClassName?: string;
  zIndex?: number;
  onCancel?: (e: MouseEvent<HTMLElement>) => void;
  onOk?: (e: MouseEvent<HTMLElement>) => void;
  children?: React.ReactNode;
}

const CloseIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const Modal: React.FC<ModalProps> = ({
  afterClose,
  bodyStyle,
  bodyClassName,
  cancelButtonProps,
  cancelText = "Cancel",
  centered = false,
  closable = true,
  closeIcon,
  confirmLoading = false,
  hasHeader = true,
  footer,
  forceRender = false,
  maskClosable = true,
  maskStyle,
  modalRender,
  okButtonProps,
  okText = "OK",
  okType = "primary",
  style,
  title,
  open,
  width = 520,
  wrapClassName,
  zIndex = 1000,
  onCancel,
  onOk,
  children,
}) => {
  const [isVisible, setIsVisible] = useState(open);

  useEffect(() => {
    setIsVisible(open);
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open]);

  useEffect(() => {
    if (!isVisible && afterClose) {
      afterClose();
    }
  }, [isVisible, afterClose]);

  if (!isVisible && !forceRender) return null;

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    if (onCancel) onCancel(e);
  };

  const handleOk = (e: React.MouseEvent<HTMLElement>) => {
    if (onOk) onOk(e);
  };

  const modalStyle: CSSProperties = {
    ...style,
    width: typeof width === "number" ? `${width}px` : width,
  };

  const modalContent = (
    <div
      className={`fixed inset-0 overflow-auto bg-[#8C8C8C] bg-opacity-20 flex px-5 ${
        centered ? "items-center" : "items-start"
      } justify-center`}
      style={{ ...maskStyle, zIndex }}
      onClick={maskClosable ? handleCancel : undefined}
    >
      <div
        className={classNames(
          "bg-white rounded-lg shadow-xl mx-auto overflow-hidden",
          !centered && "mt-[100px]",
          wrapClassName,
        )}
        style={modalStyle}
        onClick={e => e.stopPropagation()}
      >
        {hasHeader && (
          <div className="border-b px-6 py-4 flex items-center justify-between">
            <h3 className="font-semibold text-lg">{title || ""}</h3>
            {closable && (
              <button
                onClick={handleCancel}
                className="text-gray-400 hover:text-gray-600 transition-colors"
                aria-label="Close modal"
              >
                {closeIcon || <CloseIcon />}
              </button>
            )}
          </div>
        )}
        <div className={classNames("p-6", bodyClassName)} style={bodyStyle}>
          {children}
        </div>
        {footer !== null && (
          <div className="border-t px-6 py-4 flex justify-end space-x-2">
            <button
              onClick={handleCancel}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm text-gray-700 hover:bg-gray-50 transition-colors"
              {...cancelButtonProps}
            >
              {cancelText}
            </button>
            <button
              onClick={handleOk}
              className={`px-4 py-2 rounded-md text-sm text-white transition-colors ${
                okType === "primary" ? "bg-blue-500 hover:bg-blue-600" : "bg-gray-500 hover:bg-gray-600"
              }`}
              disabled={confirmLoading}
              {...okButtonProps}
            >
              {okText}
            </button>
          </div>
        )}
      </div>
    </div>
  );

  const renderedModal = modalRender ? modalRender(modalContent) : modalContent;

  return createPortal(renderedModal, document.body);
};

export default Modal;
