import * as React from "react";
import type { SVGProps } from "react";

const KlardacomLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width={153} height={22} {...props}>
    <path
      fill="#1D2939"
      d="M91.587 16.503c0-1.304 1.08-2.36 2.384-2.36s2.383 1.056 2.383 2.36-1.08 2.36-2.383 2.36c-1.304 0-2.384-1.057-2.384-2.36M22.023 3.286h3.106V8.04l1.412 1.405 4.565-6.16h3.412l-5.953 8.127 6.73 7.33h-3.742l-6.424-6.698-.023 6.698h-3.107zM36.707 3.286h3.106v15.456h-3.106zM45.117 18.976q-1.082 0-1.977-.445a3.6 3.6 0 0 1-1.412-1.288q-.495-.843-.494-1.92 0-1.686 1.082-2.623 1.106-.938 3.13-1.124 1.319-.117 3.483-.117-.024-1.077-.565-1.733-.518-.656-1.67-.656-.895 0-1.577.492-.66.469-.8 1.311h-2.824q.141-1.404.894-2.365a4.57 4.57 0 0 1 1.906-1.405 6.3 6.3 0 0 1 2.424-.468q2.588 0 3.953 1.545 1.365 1.523 1.365 4.333v6.229h-3.106v-2.178a4.2 4.2 0 0 1-1.412 1.756q-.942.657-2.4.656m-.847-3.934q0 .772.517 1.17.541.375 1.318.375 1.271 0 2.047-.89.8-.912.777-2.341-.377-.047-1.247-.047t-1.295.047q-2.117.21-2.117 1.686M53.436 6.869h3.13V8.46q.565-.796 1.53-1.194a5.2 5.2 0 0 1 2.094-.422q.447 0 .87.07l-.494 2.881a4.5 4.5 0 0 0-1.27-.187q-1.177 0-1.977.632-.777.633-.776 1.944l.023 6.557h-3.13zM67.29 19q-1.624 0-2.942-.82a5.85 5.85 0 0 1-2.047-2.225q-.73-1.405-.73-3.091 0-1.92.73-3.326.753-1.405 2.024-2.154 1.27-.75 2.87-.75 2.965 0 4.33 2.366V3.286h2.965v15.456H71.55V16.61q-.682 1.194-1.765 1.803Q68.701 19 67.29 19m-2.612-6.16q0 .984.447 1.78.471.797 1.247 1.241a3.35 3.35 0 0 0 1.694.445q.918 0 1.67-.445a3.26 3.26 0 0 0 1.225-1.217q.447-.796.447-1.804 0-1.615-.965-2.576-.965-.983-2.4-.983-1.459 0-2.424 1.007-.942.983-.941 2.552M79.794 18.976q-1.082 0-1.977-.445a3.6 3.6 0 0 1-1.412-1.288q-.494-.843-.494-1.92 0-1.686 1.082-2.623 1.107-.938 3.13-1.124 1.319-.117 3.483-.117-.024-1.077-.565-1.733-.518-.656-1.67-.656-.895 0-1.577.492-.66.469-.8 1.311H76.17q.141-1.404.894-2.365a4.57 4.57 0 0 1 1.906-1.405 6.3 6.3 0 0 1 2.424-.468q2.589 0 3.953 1.545 1.365 1.523 1.365 4.333v6.229h-3.106v-2.178a4.2 4.2 0 0 1-1.412 1.756q-.941.657-2.4.656m-.847-3.934q0 .772.517 1.17.541.375 1.318.375 1.271 0 2.047-.89.801-.912.777-2.341-.377-.047-1.247-.047t-1.294.047q-2.118.21-2.118 1.686"
    />
    <path
      fill="#4B19D8"
      d="M15.827 18.854a3.16 3.16 0 0 1-.905 2.132 3.199 3.199 0 0 1-4.329.215 3.16 3.16 0 0 1-1.114-2.032 2.6 2.6 0 0 1-.003-.529q.012-.134.012-.272v-.043q0-.042-.003-.084a2.94 2.94 0 0 0-1.025-2.097s-1.52-1.52-1.6-1.59a2.68 2.68 0 0 0-2.739-.487 3.16 3.16 0 0 1-2.779-.409 3.13 3.13 0 0 1-1.338-2.455 3.1 3.1 0 0 1 1.174-2.537 3.15 3.15 0 0 1 2.747-.587q.148.055.302.093a2.7 2.7 0 0 0 2.166-.397c.103-.07 2.294-1.972 2.294-1.972a2.5 2.5 0 0 0 .762-1.587v-.013a3.1 3.1 0 0 0-.033-.766 3.15 3.15 0 0 1 .977-2.479 3.2 3.2 0 0 1 2.534-.864c1.677.173 2.94 1.64 2.852 3.313a3.16 3.16 0 0 1-.935 2.084 3.2 3.2 0 0 1-2.1.918 2.6 2.6 0 0 1-.531-.038 3 3 0 0 0-.27-.033q-.023-.002-.043-.003l-.085-.004a2.98 2.98 0 0 0-2.185.85S6.966 9.409 6.84 9.549a2.02 2.02 0 0 0-.077 2.597c.1.126 3.005 2.959 3.005 2.959a2.54 2.54 0 0 0 1.651.63h.013a3.2 3.2 0 0 0 .766-.093 3.2 3.2 0 0 1 2.564.772 3.17 3.17 0 0 1 1.066 2.444z"
    />
    <path
      fill="#4B19D8"
      d="M3.2 6.363A3.19 3.19 0 0 0 6.4 3.18 3.19 3.19 0 0 0 3.2 0 3.19 3.19 0 0 0 0 3.181a3.19 3.19 0 0 0 3.2 3.182M12.657 14.19a3.19 3.19 0 0 0 3.2-3.182 3.19 3.19 0 0 0-3.2-3.181 3.19 3.19 0 0 0-3.2 3.181 3.19 3.19 0 0 0 3.2 3.181M3.2 22a3.19 3.19 0 0 0 3.2-3.181 3.19 3.19 0 0 0-3.2-3.181 3.19 3.19 0 0 0-3.2 3.18A3.19 3.19 0 0 0 3.2 22"
    />
    <path
      fill="#1D2939"
      d="M116.078 14.458q-.898 2.235-2.817 3.522-1.894 1.262-4.42 1.262-2.21 0-4.056-1.044a7.87 7.87 0 0 1-2.914-2.914q-1.068-1.845-1.068-4.08 0-2.331 1.044-4.226a7.8 7.8 0 0 1 2.866-2.987q1.845-1.092 4.128-1.093 2.526 0 4.42 1.263t2.768 3.497h-1.675a5.4 5.4 0 0 0-2.234-2.428 6.16 6.16 0 0 0-3.206-.899 6.5 6.5 0 0 0-3.206.85q-1.506.85-2.428 2.405-.923 1.53-.923 3.52 0 1.944.898 3.474a6.4 6.4 0 0 0 2.405 2.38q1.505.85 3.23.85a6.4 6.4 0 0 0 3.254-.85q1.481-.874 2.356-2.502zM124.647 19.242q-1.796 0-3.278-.825a6.3 6.3 0 0 1-2.356-2.259q-.874-1.457-.874-3.254 0-1.942.85-3.4a5.7 5.7 0 0 1 2.331-2.258q1.482-.802 3.327-.802 1.87 0 3.352.802a5.65 5.65 0 0 1 2.355 2.258q.85 1.458.85 3.4 0 1.797-.85 3.254a6.07 6.07 0 0 1-2.355 2.259q-1.482.825-3.352.825m-4.954-6.338q0 1.433.705 2.574a5 5 0 0 0 1.821 1.773q1.141.63 2.404.631 1.287 0 2.429-.631a4.9 4.9 0 0 0 1.87-1.773q.728-1.14.728-2.574 0-1.603-.704-2.768a4.5 4.5 0 0 0-1.87-1.75 5.3 5.3 0 0 0-2.429-.582q-1.31 0-2.452.607-1.117.583-1.822 1.749-.68 1.14-.68 2.744M133.246 6.687h1.554v2.744q.195-.68.753-1.36.559-.705 1.433-1.165.875-.462 1.991-.462 1.652 0 2.866.947a4.2 4.2 0 0 1 1.554 2.453q.438-1.53 1.603-2.453 1.19-.947 2.817-.947 2.307 0 3.643 1.506 1.36 1.506 1.36 4.177V19h-1.53v-6.655q0-2.015-.971-3.278-.972-1.263-2.769-1.263-1.068 0-1.918.607-.826.583-1.288 1.627-.46 1.045-.461 2.332V19h-1.554v-6.655q0-1.967-1.02-3.254t-2.793-1.287q-1.068 0-1.919.607-.825.583-1.287 1.579a5.3 5.3 0 0 0-.485 2.137L134.8 19h-1.554z"
    />
  </svg>
);
export default KlardacomLogo;
