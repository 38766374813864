"use client";

import Modal from "@/app/components/MyModal";
import { createContext } from "@/helpers/createContext";
import { axiosClient } from "@/lib/axiosClient";
import { Input } from "antd";
import { RcFile } from "antd/es/upload";
import { Button, Form, message, notification, Space, Typography, Upload, UploadFile, UploadProps } from "antd/lib";
import { useTranslations } from "next-intl";
import { ReactElement, useCallback, useMemo, useState } from "react";

export const REPORT_TYPE = "other_request" as const;
export type ReportType = typeof REPORT_TYPE;

interface ReportContext {
  openReport: () => void;
}

const [Provider, useReportIssueContext] = createContext<ReportContext>();

export const ReportIssueProvider = ({ children }: { children: ReactElement }) => {
  const t = useTranslations("report");
  const [form] = Form.useForm();
  const [reportFiles, setReportFiles] = useState<
    UploadFile<{
      data?: {
        Key: string;
        message?: string;
      };
    }>[]
  >([]);
  const [isOpen, setIsOpen] = useState(false);

  const resetForm = useCallback(() => {
    form.resetFields();
  }, [form]);

  const onClose = () => {
    setIsOpen(false);
    resetForm();
  };

  const onSubmit = useCallback(
    async (values: { description: string; title: string }) => {
      const formData = new FormData();
      formData.append("type", REPORT_TYPE);
      formData.append("title", values.title);
      formData.append("description", values.description);

      if (reportFiles.length) {
        for (let i = 0; i < reportFiles.length; i++) {
          formData.append("files", reportFiles[i].originFileObj as Blob);
        }
      }

      try {
        await axiosClient.post("/v1/report", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        onClose();
        notification.success({
          message: t("Thank_you_for_your_feedback"),
          description: t("Thank_you_for_your_feedback_desc"),
        });
      } catch (err: any) {
        notification.error({
          message: t("Submit_feedback_Failed"),
          description: err?.response?.data?.message || err?.message || t("Internal_Error"),
        });
      }
    },
    [t, reportFiles, resetForm],
  );

  const propsUpload = useMemo<UploadProps<{ data?: { Key: string; message?: string } }>>(
    () => ({
      name: "file",
      beforeUpload: (file: RcFile) => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error(t("Image_must_smaller"));
          return Upload.LIST_IGNORE;
        }
        return false;
      },
      onChange({ fileList }) {
        setReportFiles(fileList);
      },
    }),
    [t],
  );

  return (
    <Provider
      value={{
        openReport: () => setIsOpen(true),
      }}
    >
      {children}
      <Modal
        open={isOpen}
        onCancel={onClose}
        footer={null}
        title={t("Send_feedback_to_Klarda")}
        bodyStyle={{ paddingTop: "10px" }}
        destroyOnClose
      >
        <Form className="flex flex-col gap-3 w-full" form={form} onFinish={onSubmit}>
          <Space direction="vertical" className="w-full">
            <Typography.Text>{t("Title")}</Typography.Text>
            <Form.Item
              rules={[{ required: true, message: t("Please_input_title"), min: 3 }]}
              name="title"
              className="m-0"
            >
              <Input maxLength={60} showCount placeholder={t("Feedback_subject")} />
            </Form.Item>
          </Space>

          <Space direction="vertical" className="w-full">
            <Typography.Text>{t("Describe_your_issue")}</Typography.Text>
            <Form.Item name="description" className="m-0">
              <Input.TextArea rows={5} maxLength={300} showCount placeholder={t("Tell_us_more_about_issue")} />
            </Form.Item>
          </Space>

          <Space direction="vertical" className="w-full mt-3">
            <Upload listType="picture" maxCount={5} {...propsUpload} multiple>
              <Button>{t("Click_to_Upload")}</Button>
            </Upload>
            <Typography.Text type="secondary">{t("Max_5_files")}</Typography.Text>
            <Typography.Text type="secondary">{t("File_types")}</Typography.Text>
          </Space>

          <Space className="w-full justify-end mt-6">
            <Button onClick={onClose} type="default">
              {t("Cancel")}
            </Button>
            <Button htmlType="submit" type="primary">
              {t("Submit")}
            </Button>
          </Space>
        </Form>
      </Modal>
    </Provider>
  );
};

export { useReportIssueContext };
export default ReportIssueProvider;
