import * as React from "react";
import type { SVGProps } from "react";

const CalendarEventIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.334 15.333L18.0953 17.9917M5.33398 13.333H12.0007M26.6673 13.333H24.6673M16.0007 9.33301V5.33301M9.33398 9.33301V5.33301M22.6673 9.33301V5.33301M13.334 22.6663H10.6673V19.9997L17.6367 12.9437C18.0019 12.5788 18.4971 12.3739 19.0133 12.3739C19.5296 12.3739 20.0247 12.5788 20.39 12.9437C20.7548 13.3089 20.9598 13.8041 20.9598 14.3203C20.9598 14.8366 20.7548 15.3318 20.39 15.697L13.334 22.6663ZM6.33399 26.6663H25.6673C26.2196 26.6663 26.6673 26.2186 26.6673 25.6663V8.99967C26.6673 8.44739 26.2196 7.99967 25.6673 7.99967H6.33398C5.7817 7.99967 5.33398 8.44739 5.33398 8.99967V25.6663C5.33398 26.2186 5.7817 26.6663 6.33399 26.6663Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CalendarEventIcon;

