import(/* webpackMode: "eager" */ "__barrel_optimize__?names=ConfigProvider!=!/opt/build/repo/node_modules/antd/es/index.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/antd/lib/theme/index.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Noto_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"variable\":\"--font-notosans\"}],\"variableName\":\"NT\"}");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/slick-carousel/slick/slick.css");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/slick-carousel/slick/slick-theme.css");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/components/ContentWrapper.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/globals.scss");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/styles/blog_content.scss");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/context/AuthContext.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/context/CurrencyContext.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/context/LanguageContext.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/context/PriceContext.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/context/ReportIssueContext.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/context/ShareContext.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/context/TimezoneContext.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/react-query-provider.tsx")