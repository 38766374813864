import { Button } from "antd";
import { useTranslations } from "next-intl";
import React from "react";
import Image from "next/image";
import ipImage from "@/asset/images/iphone.png";
import KlardaLogo from "@/asset/images/logo.svg";
import bgImage from "@/asset/images/image-apis.png";
import bgImageDecor from "@/asset/images/image-apis-decor.png";
import ArrowUpRight from "@/svg/ArrowUpRight";
import { KLARDA_APP_URL } from "@/constant/apiPath";

const KlardaAPIBanner = React.memo(() => {
  const t = useTranslations("home");
  return (
    <div>
      <div className="flex flex-col md:flex-row items-center justify-center gap-6 py-8 md:py-12">
        <div className="flex flex-col gap-6 items-center text-center md:text-left">
          <h3 className="text-2xl md:text-4xl font-extrabold text-black">{t("Start_your_journey_now")}</h3>
          <p className="text-lg md:text-xl font-normal text-gray-500">{t("Try_Klarda_Dashboard_for_free")}</p>
          <Button type="primary" href={KLARDA_APP_URL}>
            {t("Try_it_now")}
          </Button>
        </div>
        <div className="hidden md:block">
          <Image src={ipImage} alt="iphone" width={200} height={260} />
        </div>
      </div>
      <div className="bg-no-repeat bg-cover bg-center text-white" style={{ backgroundImage: `url(${bgImage.src})` }}>
        <div className="flex flex-col md:flex-row items-center justify-between gap-6 text-white py-4 md:py-6 px-4 md:px-[80px] xl:px-[168px] overflow-hidden max-w-[1920px] mx-auto">
          <div className="flex flex-col md:flex-row items-center gap-6 mb-6 md:mb-0">
            <div className="relative">
              <div
                className="hidden md:block absolute -inset-24 lg:-inset-20 bg-no-repeat bg-contain bg-center"
                style={{ backgroundImage: `url(${bgImageDecor.src})` }}
              />
              <Image src={KlardaLogo} alt={"logo-banner"} className="z-10" />
            </div>

            <div className="flex flex-col gap-6 text-center md:text-left z-10">
              <h3 className="text-2xl md:text-[38px] font-semibold">{t("Klarda_API")}</h3>
              <p className="text-lg md:text-2xl font-semibold">{t("Klarda_API_description")}</p>
            </div>
          </div>
          <Button
            size="large"
            className="border text-white border-gray-200 flex items-center gap-2"
            type="text"
            href={"https://inside.klarda.com/apis"}
            target="_blank"
          >
            {t("Get_api_now")}
            <ArrowUpRight className="text-white" fill="#fff" />
          </Button>
        </div>
      </div>
    </div>
  );
});

KlardaAPIBanner.displayName = "KlardaAPIBanner";

export default KlardaAPIBanner;
